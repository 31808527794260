<template>
  <master-component :config="config"></master-component>
</template>


<script>
import {
  getCourts,
  getNamePrefixs,
  getLawyers,
} from "./../js/dropdown-service";
import { UserRole, InputType, Usr, DialogSize } from "./../js/constants";
import { addressStructure } from "./../js/custom";
import { fakeAbbr, getFakeUrl } from "./../_helpers/fake-url";
import { getToken, isAdmin, isManagement } from "../js/authorization";

export default {
  created() {
    const config = this.config;
  },
  computed: {
    config() {
      return {
        name: "master-lawyer",
        shortName: "ทนาย",
        search: this.searchSection,
        form: this.formSection,
        table: this.tableSection,
        permission: this.permission,
        url: this.urlSection,
      };
    },
    urlSection() {
      let searchUrl = "/lawyer/search";
      let getUrl = "/lawyer";
      let addUrl = "/lawyer";
      let editUrl = "/lawyer";
      let removeUrl = "/lawyer";

      return {
        search: searchUrl,
        get: getUrl,
        add: addUrl,
        edit: editUrl,
        remove: removeUrl,
      };
    },
    searchSection() {
      return {
        rows: [
          [
            {
              name: "code",
              text: "Code",
              type: InputType.text,
            },
            {
              name: "name",
              text: "ชื่อ สกุล",
              type: InputType.text,
            },
            {
              type: InputType.offset,
            },
          ],
          [
            {
              name: "email",
              text: "Email",
              type: InputType.text,
            },
            {
              name: "court",
              text: "ศาล",
              type: InputType.dropdown,
              data: {
                promise: getCourts(),
              },
            },
            {
              type: InputType.offset,
            },
          ],
        ],
      };
    },
    permission() {
      return {
        add: [UserRole.ADMIN, UserRole.MANAGEMENT],
        edit: [UserRole.ADMIN, UserRole.MANAGEMENT],
        remove: [UserRole.ADMIN],
      };
    },
    tableSection() {
      return {
        columns: [
          {
            name: "code",
            text: "Code",
          },
          {
            name: "firstName",
            text: "ชื่อ",
          },
          {
            name: "lastName",
            text: "สกุล",
          },
          {
            name: "email",
            text: "Email",
          },
          {
            name: "reportTo.text",
            text: "Head",
          },
        ],
      };
    },
    formSection() {
      return {
        size: DialogSize.fullScreen,
        rows: [
          [
            {
              text: "Reset password",
              type: InputType.button,
              columnClass: "text-right ",
              showIf: ({ model }) => {
                let result = false;

                let token = getToken();
                let condition1 = model.id ? true : false;
                let condition2 = token.username == Usr.Support;
                let condition3 = isAdmin();
                let others = [condition2, condition3];

                if (condition1 && others.some((x) => x == true)) {
                  result = true;
                }

                return result;
              },
              buttonConfig: {
                class: "primary mb-3",
                click: ({ model }) => {
                  if (!confirm("คุณต้องการรีเซ็ตรหัสผ่านใช่หรือไม่ ?")) {
                    return;
                  }

                  let url = `/account/resetPassword/lawyer/${model.id}`;
                  // url = getFakeUrl(fakeAbbr.resetPassword) + url;

                  this.$http.post(url).then((res) => {
                    let message = `กรุณาส่งรหัสผ่านนี้ให้ user : ${res}`;
                    alert(message);
                  });
                },
              },
            },
          ],
          [
            {
              name: "namePrefix",
              text: "คำนำหน้า",
              type: InputType.dropdown,
              data: {
                promise: getNamePrefixs(),
              },
              rule: {
                required: true,
              },
            },
            {
              name: "firstName",
              text: "ชื่อ",
              type: InputType.text,
              rule: {
                required: true,
              },
            },
            {
              name: "lastName",
              text: "สกุล",
              type: InputType.text,
              rule: {
                required: true,
              },
            },
            {
              name: "idCardNumber",
              text: "เลขที่บัตรประชาชน",
              type: InputType.text,
            },
          ],
          [
            {
              name: "code",
              text: "Code",
              type: InputType.text,
            },
            {
              name: "lawyerLicenseNumber",
              text: "เลขที่ใบอนุญาตให้เป็นทนายความ",
              type: InputType.text,
            },
            {
              name: "email",
              text: "Email",
              type: InputType.text,
              rule: {
                required: true,
                //email: true,
              },
            },

            {
              name: "telNo",
              text: "เบอร์โทร",
              type: InputType.text,
            },
          ],
          [
            {
              name: "reportTo",
              text: "Head",
              type: InputType.dropdown,
              data: {
                promise: getLawyers(),
              },
            },
            {
              name: "lineId",
              text: "ไอดีไลน์ (แสดงในเอกสารฟ้อง)",
              type: InputType.text,
            },
            { type: InputType.offset },
            { type: InputType.offset },
            { type: InputType.offset },
          ],
          [
            {
              type: "section",
              text: "ที่อยู่",
            },
          ],
          [
            {
              name: "address",
              text: "ที่อยู่",
              type: InputType.address,
              default: addressStructure(),
            },
          ],
          [
            {
              type: "section",
              text: "Credential",
            },
          ],
          [
            {
              name: "userName",
              text: "User Name",
              type: InputType.text,
              disabledWhenEdit: true,
              rule: {
                min: 4,
                max: 20,
              },
            },
            {
              name: "password",
              text: "Password",
              type: InputType.password,
              hideWhenEdit: true,
              rule: {
                required: true,
                regex: "",
                min: 7,
                max: 20,
              },
            },
            {
              name: "confirmPasword",
              text: "Confirm Password",
              type: InputType.password,
              hideWhenEdit: true,
              rule: {
                confirmed: "password",
                required: true,
              },
            },
          ],
          // [
          //   {
          //     type: "section",
          //     text: "Line Connect",
          //   },
          // ],
          // [
          //   {
          //     name: "lineConnect",
          //     type: InputType.lineConnect,
          //   },
          // ],
        ],
      };
    },
  },
};
</script>
